<template>
  <div class="resource-wrapper">
    <div class="resource-nav">
      <div class="text">
        {{ $t('resource') }}
        <div class="line"></div>
      </div>
    </div>
    <div class="resource-nav-link">
      <div>
        <router-link
          v-for="item in navLink"
          :key="item.prop"
          :to="item.link"
          tag="div"
          class="resource-nav-link-router"
        >
          {{ $t(item.prop) }}
        </router-link>
      </div>
    </div>
    <div
      class="router"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import { configLink } from '@/utils'
export default {
  name: 'resource',
  metaInfo() {
    return {
      title: this.$t('resource')
    }
  },
  data() {
    return {
      navLink: [...configLink[2].list]
    }
  }
}
</script>

<style lang="scss" scoped>
.resource-wrapper {
  position: relative;
  width: 100%;
  .resource-nav {
    width: 100%;
    height: 160px;
    background: url('~@/assets/image/resource/nav.jpg') no-repeat center;
    background-size: auto 100%;
    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      font-size: 24px;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
  .resource-nav-link {
    height: 50px;
    width: 100%;
    padding: 13px 16px;
    border-bottom: 1px solid rgba(225, 225, 225, 0.8);
    position: sticky;
    top: 56px;
    background: #fff;
    z-index: 100;
    display: flex;
    justify-content: center;
    & > div {
      display: flex;
      .resource-nav-link-router {
        font-size: 13px;
        color: #888888;
        line-height: 24px;
        font-weight: bold;
        cursor: pointer;
        transition: color 0.3s;
        flex-shrink: 0;
        margin-right: 32px;
        &:last-child {
          margin-right: 0;
        }
        &.router-link-exact-active, &:hover {
          transition: color 0.3s;
          color: rgba(34, 34, 34, 1);
        }
      }
    }
  }
}
</style>
